'use client';

import { ChevronRightIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

import type { IService } from '@/src/components/home.interface';

interface IProps {
  service: IService;
  index: number;
}

function Ourimpact() {
  // const OrderingImage = [
  //   {
  //     imagePath: '/images/icons/our-impact/ordering/ordering_1@2x.png',
  //     imageWidth: 1024,
  //     alt: 'Ordering',
  //   },
  // ];
  // const ScheduleImage = [
  //   {
  //     imagePath: '/images/icons/our-impact/schedule/schedule_1@2x.png',
  //     imageWidth: 1024,
  //     alt: 'Schedule',
  //   },
  // ];
  // const StudentImage = [
  //   {
  //     imagePath: '/images/icons/our-impact/student/student_1@2x.png',
  //     imageWidth: 1024,
  //     alt: 'Student',
  //   },
  // ];

  return (
    <div className="flex flex-col items-center justify-center">
      <h3 className="text-[28px] font-[500] text-[#555659] md:text-[36px] md:text-[#3d3e40]">
        Our Impact
      </h3>
      <p className="w-[85%] text-center text-[17px] text-[#616366] md:w-[100%] md:text-[18px]">
        AbleSpace is changing the way teachers and other special ed
        professionals approach their daily work
      </p>
      <div className="mx-[100px] mb-[50px] mt-[64px] flex flex-col items-center justify-center md:mb-[100px] md:flex-row md:flex-wrap md:gap-x-[50px] lg:mb-[151px] lg:flex-nowrap lg:gap-x-[60px] xl:gap-x-[40px]">
        <div className="mb-[50px] flex w-[100%] flex-col items-center justify-center text-center md:mb-[0px] md:w-[40%] lg:w-[100%]">
          <div className="h-[128px] w-[128px]">
            {/* <OptimisedImage
              defaultImagePath="/images/icons/our-impact/ordering/ordering_1@1x.png"
              data={OrderingImage}
            /> */}
            <Image
              src={
                '/images/icons/our-impact/ordering/ordering_1@2x.png' ||
                '/images/icons/our-impact/ordering/ordering_1@1x.png'
              }
              width={1024}
              height={1024}
              alt="Ordering"
            />
          </div>
          <p className="w-[150%] pt-[8px] text-[20px] text-[#3d403d] md:w-[110%] md:pt-[24px] lg:w-[130%] xl:w-[90%] xl:text-[22px]">
            <span className="font-semibold">90%</span> report improvement in
            compliance
          </p>
        </div>
        <div className="lg:h-[120px] lg:w-[4px] lg:rounded-[16px] lg:border lg:border-[#a0dcff] lg:border-opacity-[0.1]"></div>
        <div className="mb-[50px] flex w-[100%] flex-col items-center justify-center text-center md:mb-[0px] md:w-[40%] lg:w-[100%]">
          <div className="h-[128px] w-[128px]">
            {/* <OptimisedImage
              defaultImagePath="/images/icons/our-impact/schedule/schedule_1@1x.png"
              data={ScheduleImage}
            /> */}
            <Image
              src={
                '/images/icons/our-impact/schedule/schedule_1@2x.png' ||
                '/images/icons/our-impact/schedule/schedule_1@1x.png'
              }
              width={1024}
              height={1024}
              alt="Schedule"
            />
          </div>
          <p className="w-[130%] pt-[8px] text-[20px] text-[#3d403d] md:w-[90%] md:pt-[24px] lg:w-[110%] xl:w-[70%] xl:text-[22px]">
            Save
            <span className="font-semibold"> 4 hours/week</span> on average
          </p>
        </div>
        <div className="lg:h-[120px] lg:w-[4px] lg:rounded-[16px] lg:border lg:border-[#a0dcff] lg:border-opacity-[0.1]"></div>
        <div className="mb-[50px] flex w-[100%] flex-col items-center justify-center text-center md:mb-[0px] md:mt-12 lg:mt-0">
          <div className="h-[128px] w-[128px]">
            {/* <OptimisedImage
              defaultImagePath="/images/icons/our-impact/student/student_1@1x.png"
              data={StudentImage}
            /> */}
            <Image
              src={
                '/images/icons/our-impact/student/student_1@2x.png' || 
                '/images/icons/our-impact/schedule/schedule_1@1x.png'
              }
              width={1024}
              height={1024}
              alt="Student"
            />
          </div>
          <p className="w-[150%] pt-[8px] text-[20px] text-[#3d403d] md:w-[42%] md:pt-[24px] lg:w-[130%] xl:w-[90%] xl:text-[22px]">
            <span className="font-semibold">80%</span> report improvement in
            collaboration
          </p>
        </div>
      </div>
    </div>
  );
}

export const Service = ({ service, index }: IProps) => {
  const router = useRouter();
  const { imageUrl, title, description, chip, alt, redirectUrl, buttonText } = service;

  // const serviceImage = [
  //   {
  //     imagePath: `${imageUrl}/service_${index}-2x.png`,
  //     imageWidth: 1024,
  //     alt,
  //   },
  // ];

  const defaultImage: string = `${imageUrl}/service_${index}-1x.png`;
  return (
    <>
      {chip === 'Schools/Districts' ? (
        <div className="mt-16 py-5 md:mt-20 lg:mt-28">
          <Ourimpact />
          <div
            className={`mx-6 flex flex-col items-center justify-center gap-y-2 rounded-3xl py-8 md:mx-8 md:flex-row  md:justify-center md:gap-x-[8%] md:gap-y-0 md:bg-[#fafdff] lg:items-start lg:px-[4%] ${
              index % 2 === 0 ? 'md:flex-row-reverse' : ''
            }`}
          >
            <div className="w-[80%] md:w-[50%] lg:w-[60%] ">
              {/* <OptimisedImage
                data={serviceImage}
                defaultImagePath={defaultImage}
              /> */}
              <Image
                src={`${imageUrl}/service_${index}-2x.png` || defaultImage}
                width={1024}
                height={1024}
                alt={alt}
              />
            </div>
            <div className="mt-2 flex flex-col text-center md:-mt-2 md:w-[55%] md:text-left lg:max-w-[35%]">
              <div className="self-center rounded-2xl bg-[#F6FFF2] px-4 py-3 text-[15px] font-medium text-[#76A675] md:self-start">
                {chip}
              </div>
              <div className="mt-4 text-xl font-medium text-[#3D3E40] md:text-2xl lg:text-4xl">
                {title}
              </div>
              <div className="py-4 text-[15px] font-normal text-[#616366] md:text-[18px]">
                {description}
              </div>
              <button
                className={
                  'mt-2 flex cursor-pointer gap-x-2 self-center rounded-md border-2 border-[#318AE5] px-3 py-2 text-base font-medium text-[#318AE5] hover:bg-[#318AE5] hover:text-white md:self-start lg:mt-6'
                }
                onClick={() => router.push('/schools-and-districts')}
              >
                Learn More <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`mx-6 mt-10 flex flex-col items-center justify-center gap-y-2 md:mx-4 md:flex-row md:gap-x-[4%] md:gap-y-0 md:px-[4%] lg:gap-x-[8%] ${
            index % 2 === 0 ? 'mt-12 md:flex-row-reverse xl:mt-0 ' : ''
          }`}
        >
          <div className="w-[80%] md:w-[50%] lg:w-[60%] ">
            {/* <OptimisedImage
              data={serviceImage}
              defaultImagePath={defaultImage}
            /> */}
            <Image
              src={`${imageUrl}/service_${index}-2x.png` || defaultImage}
              width={1024}
              height={1024}
              alt={alt}
            />
          </div>
          <div className="mt-1 flex flex-col text-center md:-mt-4 md:w-[55%] md:text-start lg:max-w-[35%]">
            <div className="self-center rounded-2xl bg-[#F6FFF2] px-4 py-3 text-[15px] font-medium text-[#3F603E] md:self-start">
              {chip}
            </div>
            <div className="mt-4 text-xl font-medium text-[#3D3E40] md:text-2xl lg:text-4xl">
              {title}
            </div>
            <div className="w-[100%] py-4 text-[15px] font-normal text-[#616366] md:w-[100%] md:text-[18px]">
              {description}
            </div>
            <button
              className={
                'mt-2 flex cursor-pointer gap-x-2 self-center rounded-md border-2 border-[#318AE5] px-3 py-2 text-base font-medium text-[#318AE5] hover:bg-[#318AE5] hover:text-white md:self-start lg:mt-6'
              }
              onClick={() => {
                if(redirectUrl) {router.push(redirectUrl);} else {router.push('/features');}
                }}
            >
              { buttonText ? buttonText : "View All Features"} <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
